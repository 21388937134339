import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLoading,
  IonNote,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useMachine } from '@xstate/react'
import { rocketSharp } from 'ionicons/icons'
import { HODL } from '../../constants'
import { formatMcap, isTouchDevice, machine } from './moon-view-machine'
import './style.css'

export function MoonView() {
  const [current, send] = useMachine(machine)

  const { coin, multiplier = 1, projectedRank, hodl = HODL } = current.context

  const isLoading = current.matches('loading')
  const hasLoaded = current.matches('loaded')
  const hasError = current.matches('error')

  if (hasError) {
    return (
      <div className="MoonView-error">
        <h3>😓 Oops. Things didn't go as expected.</h3>
        <h3>Please try refreshing</h3>
      </div>
    )
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="search" />
          </IonButtons>
          <IonTitle className="MoonView-title">
            <span>🧹</span>
            <span>Coin Broom</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="MoonView">
        <IonLoading isOpen={isLoading} message={'Please wait...'} />
        {hasLoaded && (
          <>
            <section className="sticky">
              <div className="bubbles">
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
                <div className="bubble"></div>
              </div>
            </section>

            <div className="MoonView-container">
              <aside className="MoonView-rocket-container">
                <div
                  className="MoonView-rocket-item"
                  onClick={() => send('MOON_CLKD')}
                >
                  🌑
                </div>
                <div
                  id="rocket"
                  className="MoonView-rocket-item"
                  style={{ bottom: `${multiplier / 2}%` }}
                >
                  🚀
                </div>
                <div className="MoonView-content-container MoonView-rocket-trajectory"></div>
                <div
                  className="MoonView-rocket-item"
                  onClick={() => send('EARTH_CLKD')}
                >
                  🌎
                </div>
              </aside>
              <main className="MoonView-main-container">
                <header className="MoonView-header-container">
                  <div className="MoonView-header">
                    <img src={coin.image}></img>

                    <IonText color="light">
                      <h2>{coin.name}</h2>
                    </IonText>
                  </div>

                  <IonItem>
                    <IonNote color="primary" className="MoonView-hodl-label">
                      HODL
                    </IonNote>
                    <IonInput
                      className="MoonView-header-hodl-number"
                      type="number"
                      value={hodl}
                      onIonChange={(e) =>
                        send({
                          type: 'HODL_CHGD',
                          hodl: parseInt(e.detail.value!, 10),
                        })
                      }
                    ></IonInput>
                    <IonNote color="primary" className="MoonView-hodl-label">
                      Coins
                    </IonNote>
                  </IonItem>
                  <IonText color="light" className="MoonView-content-container">
                    <IonNote color="light">Your worth 💰</IonNote>
                    <h2>
                      {Number(
                        coin.current_price * hodl * multiplier
                      ).toLocaleString()}{' '}
                      USD
                    </h2>
                  </IonText>
                </header>
                <section className="MoonView-main-section">
                  <div>
                    <div className="MoonView-pricing-row MoonView-pricing-header-row">
                      <div></div>
                      <div className="MoonView-pricing-now">Now</div>
                      <div className="MoonView-projection">
                        <IonText>{multiplier}x</IonText>
                      </div>
                    </div>
                    <div className="MoonView-pricing-row MoonView-content-container">
                      <div className="MoonView-pricing-label">Price</div>
                      <div className="MoonView-pricing-now">
                        {Number(coin.current_price).toLocaleString()}
                      </div>
                      <div className="MoonView-content-container MoonView-projection">
                        {Number(
                          (coin.categories.includes('Rebase Tokens')
                            ? 1
                            : multiplier) * coin.current_price
                        ).toLocaleString()}
                      </div>
                    </div>
                    <div className="MoonView-pricing-row MoonView-content-container">
                      <div className="MoonView-pricing-label">Cap </div>
                      <div className="MoonView-pricing-now">
                        {formatMcap(coin.market_cap)}
                      </div>
                      <div className="MoonView-content-container MoonView-projection">
                        {formatMcap(multiplier * coin.market_cap)}
                      </div>
                    </div>
                    <div className="MoonView-pricing-row MoonView-content-container">
                      <div className="MoonView-pricing-label">Rank</div>
                      <div className="MoonView-pricing-now">
                        {coin.market_cap_rank}
                      </div>
                      <div className="MoonView-content-container MoonView-projection">
                        {projectedRank ?? coin.market_cap_rank}
                      </div>
                    </div>
                  </div>
                  <div
                    className="MoonView-pump-container"
                    onClick={() => {
                      const touchEnabled = isTouchDevice()
                      !touchEnabled && send('PUMP')
                    }}
                    onTouchStart={(ev) => {
                      send('TOUCH_START')
                    }}
                    onTouchEnd={() => send('TOUCH_END')}
                  >
                    <IonFabButton
                      color="light"
                      title="Pump"
                      className="MoonView-pump-symbol"
                      mode="ios"
                    >
                      <IonIcon icon={rocketSharp} color="danger" />
                    </IonFabButton>
                    <IonButton
                      color="light"
                      className="MoonView-pump-button"
                      mode="ios"
                    >
                      <IonNote color="dark">To The Moon</IonNote>
                    </IonButton>
                  </div>
                </section>
              </main>
            </div>
          </>
        )}
      </div>
    </>
  )
}
