import { assign, Machine, send } from 'xstate'
import { API, MOON_SHOT, PER_PAGE, PUMP_DELAY } from '../../constants'
import { Coin, CoinResponse } from '../../types'

type Context = {
  // master list of coins
  topCoins: Coin[]

  // after getting coin details
  coin: Coin

  // user input
  multiplier?: number

  // projected rank after multiplication (mooning)
  projectedRank?: number

  hodl: number
}

type Schema = {
  states: {
    loading: {}
    loaded: {
      states: {
        waiting: {}
        pumping: {}
      }
    }
    error: {}
  }
}

/**
 * Utility
 */

/**
 * Converts market cap into B or M or K
 * @param value as number
 * @returns string | number
 * Thanks to:
 * https://stackoverflow.com/questions/36734201/how-to-convert-numbers-to-million-in-javascript/36734774
 */
export const formatMcap = (value: number) =>
  // nine Zeroes for Billions
  Math.abs(Number(value)) >= 1.0e9
    ? (Math.abs(Number(value)) / 1.0e9).toFixed(2) + ' B'
    : // six Zeroes for Millions
    Math.abs(Number(value)) >= 1.0e6
    ? (Math.abs(Number(value)) / 1.0e6).toFixed(2) + ' M'
    : // three Zeroes for Thousands
    Math.abs(Number(value)) >= 1.0e3
    ? (Math.abs(Number(value)) / 1.0e3).toFixed(2) + ' K'
    : Math.abs(Number(value))

/**
 *
 * @param topCoins Top Coins from market data in ascending order
 * @param marketCap market cap of the coin
 * @returns Projection
 */
const computeCoinProjection = (
  marketCap: number,
  topCoins: Coin[]
): { rank: number } => {
  let rank = -1

  // find the rank that closely matches with this market cap
  // note: we are searching from higher rank to lower rank
  for (let idx = 0; idx < topCoins.length; idx++) {
    // if the market cap is greater than or equal to this
    // coin then break
    if (marketCap >= topCoins[idx].market_cap) {
      rank = topCoins[idx].market_cap_rank
      break
    }
  }

  return { rank }
}

export const isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  )
}

const loaded = 'loaded'
const pumping = 'pumping'
export const machine = Machine<Context, Schema>({
  id: 'MoonView',
  initial: 'loading',
  states: {
    loading: {
      entry: assign((_) => ({
        multiplier: parseInt(
          new URLSearchParams(window.location.search).get('x') ?? '1',
          10
        ),
      })),
      on: {
        CANCEL: loaded,
      },
      invoke: {
        src: async () => {
          const coinId = new URLSearchParams(window.location.search).get('coin')

          const req = await fetch(
            `${API}/coins/${coinId}?localization=false&community_data=false&tickers=false&developer_data=false&sparkline=false`
          )

          const {
            image,
            market_data,
            description,
            ...basic
          }: CoinResponse = await req.json()

          const coin: Coin = {
            ...basic,
            description: description.en,
            image: image.small,
            ath: market_data.ath.usd,
            atl: market_data.atl.usd,
            market_cap: market_data.market_cap.usd,
            total_volume: market_data.total_volume.usd,
            current_price: market_data.current_price.usd,
            market_cap_rank: market_data.market_cap_rank,
            max_supply: market_data.max_supply,
            circulating_supply: market_data.circulating_supply,
            total_supply: market_data.total_supply,
          }

          return { coin }
        },
        onDone: {
          actions: assign((_, event) => ({
            ...event.data,
          })),
          target: loaded,
        },
        onError: 'error',
      },
    },
    loaded: {
      invoke: {
        src: async ({ coin }) => {
          const { market_cap_rank } = coin

          // get the page to get market data
          const page =
            Math.round(market_cap_rank / PER_PAGE) +
            (market_cap_rank % PER_PAGE ? 1 : 0)

          // get all pages till the top rank
          const reqs = await Promise.all(
            Array.from(Array(page)).map((_, idx) =>
              fetch(
                `${API}/coins/markets?vs_currency=usd&per_page=${PER_PAGE}&page=${
                  idx + 1
                }`
              )
            )
          )

          const topCoins: Coin[] = (
            await Promise.all(reqs.map((req) => req.json()))
          ).flat()

          return { topCoins }
        },
        onDone: {
          actions: assign((_, event) => ({
            ...event.data,
          })),
        },
      },
      on: {
        HODL_CHGD: {
          actions: assign((_, { hodl }) => ({
            hodl: isNaN(hodl) ? 0 : hodl,
          })),
        },
        PUMP: {
          actions: assign(
            ({ coin, multiplier = 1, topCoins, projectedRank }) => {
              const marketCap = coin.market_cap * multiplier

              const { rank = projectedRank } = topCoins
                ? computeCoinProjection(marketCap, topCoins)
                : { rank: coin.market_cap_rank }

              return {
                multiplier: multiplier + 1,
                // if the rank couldn't be computed
                // then default to its original rank
                projectedRank: rank === -1 ? coin.market_cap_rank : rank,
              }
            }
          ),
        },
        EARTH_CLKD: {
          // do a reset
          actions: [
            assign((_) => ({
              multiplier: 0,
            })),
            send('PUMP'),
          ],
        },
        MOON_CLKD: {
          // jump
          actions: [
            assign((_) => ({
              multiplier: MOON_SHOT,
            })),
            send('PUMP'),
          ],
        },
      },

      // to handle continuous pump
      initial: 'waiting',
      states: {
        waiting: {
          on: {
            TOUCH_START: pumping,
          },
        },
        pumping: {
          entry: send('PUMP'),
          after: {
            [PUMP_DELAY]: pumping,
          },

          on: {
            TOUCH_END: 'waiting',
          },
        },
      },
    },
    error: {
      type: 'final',
    },
  },
})
