import { assign, Machine } from 'xstate'
import { MAX_SEARCH_LIST } from '../../constants'
import { CoinListItem } from '../../types'

type Context = {
  // master list of coins
  coins: CoinListItem[]

  // user input
  search: string

  // coins that match the search
  filteredCoins: CoinListItem[]
}

type Schema = {
  states: {
    loading: {}
    loaded: {}
  }
}

const loaded = 'loaded'

export const machine = Machine<Context, Schema>({
  id: 'CoinSearch',
  initial: 'loading',
  states: {
    loading: {
      on: {
        CANCEL: loaded,
      },
      invoke: {
        src: async () => {
          const coins: CoinListItem[] = (await import('./market')).default

          return { coins }
        },
        onDone: {
          actions: assign((_, event) => ({
            ...event.data,
          })),
          target: loaded,
        },
        onError: {
          actions: () => console.log('failed'),
        },
      },
    },
    loaded: {
      on: {
        SEARCH: [
          {
            // if there is a valid filter
            cond: (_, { value }) => !!value,
            actions: assign((ctx, { value: search }) => {
              // filter and get only the first 10 items
              const filteredCoins = ctx.coins
                .filter(
                  (coin) =>
                    coin.name.toLowerCase().includes(search.toLowerCase()) ||
                    coin.symbol.toLowerCase().includes(search.toLowerCase())
                )
                .slice(0, MAX_SEARCH_LIST)
                .sort((a, b) => a.market_cap_rank - b.market_cap_rank)

              return {
                search,
                filteredCoins,
              }
            }),
          },
          {
            actions: assign((_) => ({
              filteredCoins: [],
              search: '',
            })),
          },
        ],
        COIN_CLKD: {
          actions: (_, { coin }) =>
            (window.location.href = `moonview?coin=${coin.id}`),
        },
      },
    },
  },
})
