import {
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonSearchbar,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useMachine } from '@xstate/react'
import { machine } from './coin-search-machine'
import './style.css'

export function CoinSearch() {
  const [current, send] = useMachine(machine)

  const { search, filteredCoins } = current.context

  const isLoading = current.matches('loading')

  return (
    <div className="container CoinSearch-container">
      <IonLoading
        isOpen={isLoading}
        onDidDismiss={() => send('CANCEL')}
        message={'Please wait...'}
      />
      <IonToolbar>
        <IonTitle className="CoinSearch-title">
          <span>🧹</span>
          <span>Coin Broom</span>
        </IonTitle>
      </IonToolbar>
      <IonSearchbar
        className="CoinSearch-searchbar"
        value={search}
        placeholder="Search your favorite coin"
        onIonChange={({ detail }) =>
          send({ type: 'SEARCH', value: detail.value })
        }
      ></IonSearchbar>

      <IonList className="CoinSearch-list">
        {filteredCoins?.map((coin) => (
          <IonItem key={coin.id} className="CoinSearch-coin-item">
            <IonThumbnail slot="start">
              <IonImg src={coin.image} />
            </IonThumbnail>
            <IonLabel onClick={() => send({ type: 'COIN_CLKD', coin })}>
              {coin.name}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </div>
  )
}
