export const API = 'https://api.coingecko.com/api/v3'
export const PER_PAGE = 250

/**
 * Number of coins to filter in the list
 */
export const MAX_SEARCH_LIST = 10

/**
 * The number of milliseconds to wait
 * when the user is holding on the pump button
 * before actually trigger a pump
 */
export const PUMP_DELAY = 300

/**
 * The multiplier value when reaching moon
 */
export const MOON_SHOT = 179

/**
 * Number of coins to hodl
 */
export const HODL = 100
